/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { Org, OrgFormularyItem, UpdateFormularyItemPayload } from 'api/orgs/types';
import { MoreItemsState } from 'components/shared/moreItems/AlgoliaHits';

import { updateOrgFormularyItems } from 'api/orgs';
import { useSupplierKind } from 'hooks/useSupplierKind';
import { MoreItems } from 'components/shared/moreItems';
import { Modal } from 'components/ui/Modal';

interface Props {
  close: () => void;
  org: Org;
  items: OrgFormularyItem[];
  onSuccess: () => void;
}

export const AddFormularyItems = ({ close, org, items, onSuccess }: Props) => {
  const [addedItems, setAddedItems] = useState<MoreItemsState[]>([]);
  const { getSupplierKind } = useSupplierKind();
  const orgID = org.metadata.orgID;

  const { mutate } = useMutation((body: UpdateFormularyItemPayload[]) => updateOrgFormularyItems(orgID, body));

  const fillMoreItemsState = () => {
    const res: MoreItemsState[] = items.map(({ variant, price }) => {
      const kind = getSupplierKind(price.providerName);

      return {
        ...variant,
        supplier: {
          kind,
          name: price.providerName,
          sku: price.sku,
          stock: price.stock,
          displayPrice: String(price.customSellingPrice || price.sellingPrice),
          price: price.customSellingPrice || price.sellingPrice,
          connected: true,
          pricePending: false
        }
      };
    });

    setAddedItems(res);
  };

  useEffect(() => {
    fillMoreItemsState();
  }, []);

  const callback = (_items: MoreItemsState[], newItems: MoreItemsState[], op: 'create' | 'delete') => {
    const body = newItems.map((item) => ({
      sku: item.supplier.sku,
      providerName: item.supplier.name,
      op
    }));

    mutate(body, { onSuccess });
  };

  return (
    <Modal open={true} close={close} title="Add more products to formulary" className="addFormularyItemsModal">
      <MoreItems
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        org={org}
        callback={callback}
        isFormulary={true}
      />
    </Modal>
  );
};
