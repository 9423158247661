/* eslint-disable react-hooks/exhaustive-deps */
import { Field } from 'formik';
import { useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FieldError } from './FormikError';

interface Props {
  name: string;
  onChange: (obj: any) => void;
}

const apiKey = process.env.REACT_APP_GOOGLE_API_LOCAL_SECRET;

const GoogleField = ({ name, onChange }: Props) => {
  const [chosenValue, setChosenValue] = useState<any>(undefined);
  const [output, setOutput] = useState<any>(null);
  const { ref }: any = usePlacesWidget({
    apiKey,
    onPlaceSelected: (place) => setChosenValue(place),
    options: { types: [], componentRestrictions: { country: ['us', 'gb'] } }
  });

  useEffect(() => {
    if (output) {
      setOutput(null);
      setChosenValue(undefined);
    }
  }, [output]);

  useEffect(() => {
    if (chosenValue !== undefined) {
      const output = {
        name: '',
        route: '',
        streetNum: '',
        state: '',
        locality: '',
        country: '',
        zip: '',
        lat: 0,
        lng: 0
      };
      const geometry = chosenValue.geometry.location;
      const address = chosenValue.address_components;

      if (geometry) {
        output.lat = geometry.lat();
        output.lng = geometry.lng();
      }

      if (address) {
        address.forEach((i: any) => {
          if (i.types.includes('route')) output.route = i.long_name;
          if (i.types.includes('street_number')) output.streetNum = i.long_name;
          if (i.types.includes('administrative_area_level_1')) output.state = i.short_name;
          if (i.types.includes('postal_code')) output.zip = i.short_name;
          if (i.types.includes('locality') || i.types.includes('postal_town')) output.locality = i.long_name;
          if (i.types.includes('country')) output.country = i.short_name;
        });

        if (output.streetNum) {
          output.name = `${output.streetNum} ${output.route}`;
        } else {
          output.name = output.route;
        }

        onChange(output);
        setOutput(output);
      }
    }
  }, [chosenValue]);

  return (
    <div className="field">
      <label>Business address</label>
      <Field innerRef={ref} name={name} placeholder="" />
      <FieldError name={name} />
    </div>
  );
};

export default GoogleField;
