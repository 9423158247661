import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';

import { AlgoliaUser } from 'types/algolia.types';
import { ImpersonateUserPayload, User } from 'api/users/types';

import { postUserImpersonate } from 'api/users/index';
import { Modal } from 'components/ui/Modal';
import { OrgUser } from 'api/orgs/types';

interface Props {
  user: AlgoliaUser | User['metadata'] | OrgUser | null;
  close: () => void;
}

export const UserImpersonateModal = ({ user, close }: Props) => {
  const [variant, setVariant] = useState<'faliam' | 'billpay' | 'analytics'>('faliam');

  const { mutate, isLoading } = useMutation(postUserImpersonate);

  const onSubmit = (values: ImpersonateUserPayload) => {
    mutate(values, {
      onSuccess(res) {
        let url = res;

        if (variant === 'billpay') {
          if (url.includes('qa.faliam.com')) {
            url = url.replace('qa.faliam.com', 'qa.billpay.faliam.com');
          } else {
            url = url.replace('faliam.com', 'billpay.faliam.com');
          }
        }

        if (variant === 'analytics') {
          if (url.includes('qa.faliam.com')) {
            url = url.replace('qa.faliam.com', 'qa.analytics.faliam.com');
          } else {
            url = url.replace('faliam.com', 'analytics-frontend-six.vercel.app');
          }
        }

        window.open(url);
        close();
      }
    });
  };

  return (
    <Modal open={user ? true : false} close={close} title="User Impersonate" className="userImpersonateModal">
      <Formik initialValues={{ userID: user?.userID || '', reason: '' }} onSubmit={onSubmit}>
        <Form>
          <div className="group">
            <label>User ID</label>
            <p>{user?.userID}</p>
          </div>
          <div className="group">
            <label>User name</label>
            <p>{user?.name}</p>
          </div>
          <div className="group">
            <label>Reason (why you impersonate the user?)</label>
            <Field as="textarea" name="reason" placeholder="Write Reason Here..." required />
          </div>
          <div className="group up">
            <label>Platform</label>
            <div className="radio-group">
              <div
                className={`radio-item ${variant === 'faliam' ? 'active' : ''}`}
                onClick={() => setVariant('faliam')}
              >
                <div className="radio">
                  <div />
                </div>
                <label>Faliam</label>
              </div>
              <div
                className={`radio-item ${variant === 'billpay' ? 'active' : ''}`}
                onClick={() => setVariant('billpay')}
              >
                <div className="radio">
                  <div />
                </div>
                <label>BillPay</label>
              </div>

              <div
                className={`radio-item ${variant === 'analytics' ? 'active' : ''}`}
                onClick={() => setVariant('analytics')}
              >
                <div className="radio">
                  <div />
                </div>
                <label>Analytics</label>
              </div>
            </div>
          </div>
          <button className="contained" type="submit" disabled={isLoading}>
            Impersonate User
          </button>
        </Form>
      </Formik>
    </Modal>
  );
};
