import { useMutation } from 'react-query';
import { Formik, Form, Field } from 'formik';

import { NewOrgPayload } from 'api/orgs/types';

import { createOrg } from 'api/orgs';
import { Modal } from 'components/ui/Modal';
import { CountrySelect } from 'components/shared/CountrySelect';

interface Props {
  open: boolean;
  close: () => void;
}

export const NewOrganizationModal = ({ open, close }: Props) => {
  const { mutate, isLoading } = useMutation((payload: NewOrgPayload) => createOrg(payload));
  const onSubmit = (values: NewOrgPayload) =>
    mutate(values, {
      onSuccess() {
        close();
      }
    });

  return (
    <Modal open={open} close={close} className="newOrganizationModal" title="Create Organization">
      <Formik initialValues={{ name: '', email: '', phone: '', country: undefined }} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <div className="fields">
              <div className="field">
                <label htmlFor="name">Organization Name</label>
                <Field id="name" name="name" required />
              </div>
              <div className="field">
                <label htmlFor="email">Organization Email (optional)</label>
                <Field id="email" type="email" name="email" />
              </div>
              <div className="field">
                <label htmlFor="phone">Organization Phone (optional)</label>
                <Field id="phone" name="phone" />
              </div>

              <CountrySelect formik={formik} />
            </div>
            <button type="submit" className="contained" disabled={isLoading}>
              Create Organization
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
