import { useRef, useState } from 'react';
import findIndex from 'lodash/findIndex';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { Org, OrgFormularyItem, UpdateFormularyItemPayload } from 'api/orgs/types';

import useTableData from 'hooks/useTableData';
import { tableSliceData } from 'util/mix';
import { updateOrgFormularyItems } from 'api/orgs';
import { Table } from 'components/shared/table';
import { AddFormularyItems } from './AddFormularyItems';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { TableDropdownCell } from 'components/shared/table/TableDropdownCell';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { convertPrice } from 'util/prices';

interface Props {
  org: Org;
  items: OrgFormularyItem[];
  refetchFormularyItems: () => void;
}

export const FormularyItemsTable = ({ items, org, refetchFormularyItems }: Props) => {
  const [isOpenNewItemsModal, setIsOpenNewItemsModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState('');

  const countryCode = org?.metadata?.country;

  const allSortedData = orderBy(items, (el) => el.variant.title);

  const [data, setData] = useState<any[]>(allSortedData);

  const { meta, tableData } = useTableData({ allData: data });
  const { mutate } = useMutation((body: UpdateFormularyItemPayload[]) =>
    updateOrgFormularyItems(org.metadata.orgID, body)
  );

  const dotsRef = useRef(null);
  useOutsideClick(dotsRef, () => selectedCell && setSelectedCell(''));

  const onDeleteItem = ({ variant, price }: OrgFormularyItem) => {
    const body: UpdateFormularyItemPayload = {
      sku: price.sku,
      providerName: price.providerName,
      op: 'delete'
    };

    mutate([body], {
      onSuccess() {
        refetchFormularyItems();
        setSelectedCell('');
      }
    });
  };

  return (
    <>
      <div className="space" />
      <Table
        name="OrgFormularyItems"
        title="Formulary Items"
        nav={['#', 'Item Title', 'Supplier', 'SKU', 'Promotions', 'Selling Price', '']}
        allData={allSortedData}
        meta={meta}
        setData={setData}
        searchKeys={['price.sku', 'variant.title', 'variant.manufacturerCode', 'price.providerName']}
        headerContent={
          <button className="outlined lg" onClick={() => setIsOpenNewItemsModal(true)}>
            Add Items To Formulary
          </button>
        }
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((item: OrgFormularyItem) => {
              const id = `${item.variant.objectID}:${item.price.providerName}`;
              return (
                <div key={id} className="tableRow">
                  <div className="tableCell indexOf">
                    {findIndex(data, (el) => `${el.variant.objectID}:${el.price.providerName}` === id) + 1}
                  </div>
                  <TableCellTooltip content={item.variant.title} />
                  <TableCellTooltip content={item.price.providerName} />
                  <TableCellTooltip content={item.price.sku} />
                  <TableCellTooltip content={item.price.promotions?.map((item) => item.displayText).join(',')} />
                  <TableCellTooltip
                    content={
                      item.price.customSellingPrice
                        ? convertPrice(item.price.customSellingPrice, countryCode)
                        : item.price.sellingPrice < 0
                        ? 'Price Unavailable'
                        : convertPrice(item.price.sellingPrice, countryCode)
                    }
                  />
                  <TableDropdownCell id={id} active={selectedCell} onClick={setSelectedCell} ref={dotsRef}>
                    <li className="danger" onClick={() => onDeleteItem(item)}>
                      Delete Formulary Item
                    </li>
                  </TableDropdownCell>
                </div>
              );
            })}
          </div>
        }
      />
      {isOpenNewItemsModal && (
        <AddFormularyItems
          org={org}
          items={items}
          close={() => setIsOpenNewItemsModal(false)}
          onSuccess={refetchFormularyItems}
        />
      )}
    </>
  );
};
