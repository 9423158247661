import { OrderItem } from 'types/order.types';
import { OrgOrderItem } from 'types/orgs.types';
import { getPromotion } from 'types/promotions.types';
import { CountryCode } from 'types/util.types';

export type CurrencyCode = 'USD' | 'GBP';

// TODO: Add support for this format once backend is ready
// export type Price = {
//   amount: number;
//   currency_code: CurrencyCode;
// };

export const convertPrice = (price: number, countryCode?: CountryCode): string => {
  const currency: CurrencyCode = countryCode === 'GBR' ? 'GBP' : 'USD';
  const locale = currency === 'USD' ? 'en-US' : 'en-GB';

  return (price / 100).toLocaleString(locale, { style: 'currency', currency });
};

export const convertRegularPrice = (price: number, countryCode?: CountryCode): string => {
  const currency: CurrencyCode = countryCode === 'GBR' ? 'GBP' : 'USD';
  const locale = currency === 'USD' ? 'en-US' : 'en-GB';

  return price.toLocaleString(locale, { style: 'currency', currency });
};

export const orderSupplierPrice = (item: OrderItem['price'] | OrgOrderItem['price'], quantity?: number) => {
  const price = item.customSellingPrice || item.sellingPrice;
  const qty = quantity || 1;

  if (item.promotion) {
    const promotion = getPromotion(item.promotion.type, item.promotion.data);

    const total = promotion.calculateTotal(price, qty);

    return Math.round(total);
  }

  return price;
};

export const orderItemsTotalPrice = (items: any[]) =>
  items.reduce((sum, item) => sum + orderSupplierPrice(item.price) * item.quantity, 0);

export const sumOtherCosts = (otherCosts?: { [key: string]: number }) => {
  if (!otherCosts) return 0;

  return Object.values(otherCosts).reduce((sum, cost) => sum + cost, 0);
};

export const dollarsToCents = (amount: string) => {
  let str = amount.replace('$', '');
  str = str.replace(',', '');
  if (str && str.includes('.')) {
    str = str.substring(0, str.indexOf('.') + 3);
  }

  return str ? Math.round(parseFloat(str) * 100) : 0;
};
