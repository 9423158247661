import { Link } from 'react-router-dom';
import { useHits } from 'react-instantsearch-hooks';

import { AlgoliaTableProps } from 'types/table.types';
import { AlgoliaOrder } from 'types/algolia.types';

import { convertPrice } from 'util/prices';
import { formatDate } from 'util/dates';
import { AlgoliaTable } from 'components/shared/algoliaTable';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { CountryFlag } from 'components/shared/CountryFlag';

export const OrdersTable = () => {
  const { hits, results }: any = useHits();

  const table: AlgoliaTableProps = {
    name: 'Orders',
    nav: [
      'Date',
      'Order #',
      'Shipping Address',
      'Office Name',
      'Org Name',
      'Order Total',
      'Customer Status',
      'Country'
    ],
    hits,
    totalHits: results?.nbHits,
    content: (
      <div className="tableBody nested">
        {hits.map((el: AlgoliaOrder) => (
          <div key={el.objectID} className="groupWithChildren">
            <div key={el.objectID} className="tableRow">
              <TableCellTooltip content={formatDate(el.created)} />
              <TableCellTooltip content={el.orderID}>
                <Link to={`/order/${el.orgID}/${el.orderID}`} className="link">
                  {el.orderID}
                </Link>
              </TableCellTooltip>
              <TableCellTooltip content={el.address} />
              <TableCellTooltip content={el.officeName} />
              <TableCellTooltip content={el.orgID}>
                <Link to={`/org/${el.orgID}`} className="link">
                  {el.orgName}
                </Link>
              </TableCellTooltip>
              <TableCellTooltip content={convertPrice(el.total, el.country)} />
              <TableCellTooltip content={el.status}>
                <span className={`orderStatus ${el.status}`}>{el.status}</span>
              </TableCellTooltip>

              <TableCellTooltip content={el?.country || ''}>
                <CountryFlag countryCode={el?.country} />
              </TableCellTooltip>
            </div>
          </div>
        ))}
      </div>
    )
  };

  return <AlgoliaTable {...table} />;
};
