import { toast } from 'react-toastify';

import {
  CreatePurchaseOrderPayload,
  UpdatePurchaseOrderPayload,
  DeletePurchaseOrderPayload,
  AttachProviderInvoicePayload,
  UpdateProviderInvoicePayload,
  DeleteProviderInvoicePayload,
  ConfirmPurchaseOrderPayload,
  CancelPurchaseOrderConfirmationPayload,
  CreatePurchaseOrderShipmentPayload,
  DeletePurchaseOrderShipmentPayload,
  CreateFaliamInvoice,
  DeleteFaliamInvoice,
  PayFaliamInvoicePayload,
  CreateCsOverridePayload,
  DeleteCsOverridePayload,
  DeleteBackOrderPayload,
  UpdateBackOrderPayload,
  CreateBackOrderPayload,
  UpdateFaliamInvoice
} from './types';

import { apiCall, BASE_URL_VARIANTS } from 'api';
import { Order } from 'types/order.types';

// Purchase Order

export const createPurchaseOrder = async ({ orgID, orderID, body }: CreatePurchaseOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/purchaseorders`,
      method: 'POST',
      data: body
    });

    toast('The purchase order has been created.', { type: 'success' });
    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updatePurchaseOrder = async ({
  orgID,
  orderID,
  purchaseOrderID,
  body
}: UpdatePurchaseOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/purchaseorders/${purchaseOrderID}`,
      method: 'PATCH',
      data: body
    });

    toast('The purchase order has been updated.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const deletePurchaseOrder = async ({
  orgID,
  orderID,
  purchaseOrderID
}: DeletePurchaseOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/purchaseorders/${purchaseOrderID}`,
      method: 'DELETE'
    });

    toast('The purchase order has been deleted.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Confirmation Purchase Order

export const confirmPurchaseOrder = async ({
  orgID,
  orderID,
  purchaseOrderID,
  body
}: ConfirmPurchaseOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/purchaseorders/${purchaseOrderID}/confirmation`,
      method: 'PUT',
      data: body
    });

    toast('The purchase order has been confirmed.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updatePurchaseOrderConfirmation = async ({
  orgID,
  orderID,
  purchaseOrderID,
  body
}: ConfirmPurchaseOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/purchaseorders/${purchaseOrderID}/confirmation`,
      method: 'PATCH',
      data: body
    });

    toast.success('The purchase order confirmation has been updated.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const cancelPurchaseOrderConfirmation = async ({
  orgID,
  orderID,
  purchaseOrderID
}: CancelPurchaseOrderConfirmationPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/purchaseorders/${purchaseOrderID}/confirmation`,
      method: 'DELETE'
    });

    toast('The purchase order has been cancelled.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Provider Invoices

export const attachProviderInvoice = async ({ orgID, orderID, body }: AttachProviderInvoicePayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/provider-invoices`,
      method: 'POST',
      data: body
    });

    toast.success('Provider invoice has been attched.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateProviderInvoice = async ({
  orgID,
  orderID,
  invoiceID,
  body
}: UpdateProviderInvoicePayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/provider-invoices/${invoiceID}`,
      method: 'PATCH',
      data: body
    });

    toast.success('Provider invoice has been updated.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const deleteProviderInvoice = async ({
  orgID,
  orderID,
  invoiceID
}: DeleteProviderInvoicePayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/provider-invoices/${invoiceID}`,
      method: 'DELETE'
    });

    toast('Provider invoice has been deleted.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Faliam Invoices

export const createFaliamInvoice = async ({ orgID, orderID, body }: CreateFaliamInvoice): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/faliam-invoices`,
      method: 'POST',
      data: body
    });

    toast('Faliam invoice has been created.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateFaliamInvoice = async ({ orgID, orderID, invoiceID, body }: UpdateFaliamInvoice): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/faliam-invoices/${invoiceID}`,
      method: 'PATCH',
      data: body
    });

    toast('Faliam invoice has been updated.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const deleteFaliamInvoice = async ({ orgID, orderID, invoiceID }: DeleteFaliamInvoice): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/faliam-invoices/${invoiceID}`,
      method: 'DELETE'
    });

    toast('Faliam invoice has been deleted.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// TODO: Ask George for this one
export const payFaliamInvoice = async ({
  orgID,
  orderID,
  invoiceID,
  paymentID,
  checkInfo
}: PayFaliamInvoicePayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  const body: any = {};
  if (paymentID) body.paymentID = paymentID;
  if (checkInfo) body.checkInfo = checkInfo;

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/invoices/${invoiceID}/payment`,
      method: 'POST',
      data: body
    });

    toast('Invoice has been paid.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Purchase Order Shipment
export const createPuchaseOrderShipment = async ({
  orgID,
  orderID,
  body
}: CreatePurchaseOrderShipmentPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/shipments`,
      method: 'PUT',
      data: body
    });

    toast.success('The purchase order shipment has been created.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const deletePuchaseOrderShipment = async ({
  orgID,
  orderID,
  shipmentID
}: DeletePurchaseOrderShipmentPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/shipments/${shipmentID}`,
      method: 'DELETE'
    });

    toast.success('The purchase order shipment has been deleted.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// CS Override

export const createCsOverride = async ({ orgID, orderID, body }: CreateCsOverridePayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/csoverride`,
      method: 'POST',
      data: body
    });

    toast('CS Override has been created.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const deleteCsOverride = async ({ orgID, orderID, overrideID }: DeleteCsOverridePayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/csoverride/${overrideID}`,
      method: 'DELETE'
    });

    toast('CS Override has been deleted.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

// Back Orders

export const createBackOrder = async ({ orgID, orderID, body }: CreateBackOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/backorders`,
      method: 'POST',
      data: body
    });

    toast.success('Back order has been created.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const updateBackOrder = async ({
  orgID,
  orderID,
  backOrderID,
  body
}: UpdateBackOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/backorders/${backOrderID}`,
      method: 'PATCH',
      data: body
    });

    toast('Back order has been cancelled.', { type: 'success' });

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};

export const deleteBackOrder = async ({ orgID, orderID, backOrderID }: DeleteBackOrderPayload): Promise<Order> => {
  const request = await apiCall(BASE_URL_VARIANTS.ORDERS_URL);

  try {
    const { data } = await request({
      url: `/organizations/${orgID}/orders/${orderID}/backorders/${backOrderID}`,
      method: 'DELETE'
    });

    toast.success('Back order has been deleted.');

    return data;
  } catch (error: any) {
    throw toast(error.message, { type: 'error' });
  }
};
