import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import orderBy from 'lodash/orderBy';

import { HandleCellOrders } from 'types/table.types';
import { OrgOrder } from 'types/orgs.types';
import { Org } from 'api/orgs/types';

import { formatOffice } from 'util/offices';
import { convertPrice } from 'util/prices';
import { formatDate } from 'util/dates';
import useTableData from 'hooks/useTableData';
import { Table } from 'components/shared/table';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { OrderItemsSuppliersModal } from 'components/shared/orgs/OrderItemsSuppliersModal';

interface Props {
  org: Org;
  orders: OrgOrder[];
  openCreateOrderModal: () => void;
}

export const OrgOrdersTable = ({ org, orders, openCreateOrderModal }: Props) => {
  const orgID = org.metadata.orgID;
  const allOrders = orderBy(orders, 'created', 'desc');
  const [data, setData] = useState(allOrders);
  const [activeSupplierModal, setActiveSupplierModal] = useState<OrgOrder | null>(null);
  const { meta, tableData } = useTableData({ allData: data });

  const onCreateOrder = () => {
    if (org.offices.length) return openCreateOrderModal();

    toast('First, you need to create an office.', {
      type: 'info'
    });
  };

  useEffect(() => {
    setData(orderBy(orders, 'created', 'desc'));
  }, [orders]);

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res = allOrders;

    switch (cell) {
      case 'Placed On':
        res = orderBy(allOrders, (el) => el.created, direction === 'asc' ? 'desc' : 'asc');
        break;
      case 'Order #':
        res = orderBy(allOrders, (el) => el.orderID, direction);
        break;
      case 'Office Name':
        res = orderBy(allOrders, (el) => el.office.name, direction);
        break;
      case 'Address':
        res = orderBy(allOrders, (el) => el.office.name, direction);
        break;
      case 'Status':
      case 'Invoice Status':
        res = orderBy(allOrders, (el) => el.status, direction);
        break;
      case '# Suppliers':
        res = orderBy(allOrders, (el) => el.providerCount, direction);
        break;
      case '# Items':
        res = orderBy(allOrders, (el) => el.itemCount, direction);
        break;
      case 'Order Total':
        res = orderBy(allOrders, (el) => el.total, direction);
        break;
    }

    setData(res);
  };

  return (
    <>
      <div className="space" />
      <Table
        name="OrgOrders"
        title="Orders History"
        nav={['Placed On', 'Order #', 'Office Name', 'Address', 'Status', '# Suppliers', '# Items', 'Order Total']}
        allData={allOrders}
        setData={setData}
        searchKeys={['orderID', 'office.name']}
        handleCellOrders={handleCellOrders}
        meta={meta}
        headerContent={
          <button className="outlined lg" onClick={onCreateOrder}>
            Create Order
          </button>
        }
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((order: OrgOrder) => (
              <div key={order.orderID} className="tableRow">
                <TableCellTooltip content={formatDate(order.created)} />
                <TableCellTooltip content={order.orderID}>
                  <Link to={`/order/${orgID}/${order.orderID}`} className="link">
                    {order.orderID}
                  </Link>
                </TableCellTooltip>
                <TableCellTooltip content={order.office.name} />
                <TableCellTooltip content={formatOffice(order.office)} />
                <TableCellTooltip content={order.status}>
                  <div className={`badge orderStatusBG ${order.status}`}>{order.status}</div>
                </TableCellTooltip>
                <TableCellTooltip content={order.providerCount}>
                  <span className="linked" onClick={() => setActiveSupplierModal(order)}>
                    {order.providerCount}
                  </span>
                </TableCellTooltip>
                <TableCellTooltip content={order.itemCount}>
                  <span>{order.itemCount}</span>
                </TableCellTooltip>
                <TableCellTooltip content={convertPrice(order.total, org?.metadata?.country)} />
              </div>
            ))}
          </div>
        }
      />
      {activeSupplierModal && (
        <OrderItemsSuppliersModal order={activeSupplierModal} close={() => setActiveSupplierModal(null)} />
      )}
    </>
  );
};
