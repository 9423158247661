import { UKFlagIcon } from 'assets/icons/UKFlagIcon';
import { USAFlagIcon } from 'assets/icons/USAFlagIcon';
import { CountryCode } from 'types/util.types';

type Props = {
  countryCode?: CountryCode;
};

export const CountryFlag = ({ countryCode }: Props) => {
  const props = { width: 24, height: 24 };

  switch (countryCode) {
    case 'USA':
      return <USAFlagIcon {...props} />;

    case 'GBR':
      return <UKFlagIcon {...props} />;

    default:
      return <USAFlagIcon {...props} />;
  }
};
