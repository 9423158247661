import { useRefinementList } from 'react-instantsearch-hooks';

import { InputField } from 'components/ui/Input';
import { MarkdownField } from 'components/ui/MarkdownInput';
import { isActiveClass } from 'util/classes';
import { SelectInput } from 'components/ui/SelectInput';

interface Props {
  formik: any;
}

export const GeneralPart = ({ formik }: Props) => {
  const { items: subcategories } = useRefinementList({
    attribute: 'categories.lvl1',
    limit: 100000,
    sortBy: ['name:asc']
  });
  const { items: manufacturers } = useRefinementList({
    attribute: 'manufacturer',
    limit: 100000,
    sortBy: ['name:asc']
  });

  return (
    <div className="paper">
      <div className="paperHeader">
        <h2>Product information</h2>
      </div>
      <div className="paperBody">
        <InputField formik={formik} name="title" label="Name" placeholder="Product title" />
        <MarkdownField formik={formik} name="description" label="Description" />
        <SelectInput formik={formik} label="Category" name="subcategory" options={subcategories} />
        <SelectInput formik={formik} label="Manufacturer" name="manufacturer" options={manufacturers} isCreatable />
        <InputField formik={formik} name="manufacturerCode" label="Manufacturer Code" placeholder="Code" />
        <div className="input-field w-fit flexAlign gg8">
          <label>Unavailable</label>
          <div className="switchBtn" onClick={() => formik.setFieldValue('unavailable', !formik.values.unavailable)}>
            <div className={isActiveClass('buffer', formik.values.unavailable)} />
          </div>
        </div>
      </div>
    </div>
  );
};
