import { useHits } from 'react-instantsearch-hooks';
import { Link } from 'react-router-dom';

import { AlgoliaTableProps } from 'types/table.types';
import { AlgoliaOrg } from 'types/algolia.types';

import { TableCellTooltip } from 'components/shared/table/TableCell';
import { AlgoliaTable } from 'components/shared/algoliaTable';
import { formatDate } from 'util/dates';
import { CountryFlag } from 'components/shared/CountryFlag';

export const OrganizationsTable = () => {
  const { hits, results }: any = useHits();

  const table: AlgoliaTableProps = {
    name: 'Organizations',
    nav: ['Date Created', 'Org #', 'Org Name', 'Org Email', 'Org Phone', 'Country'],
    hits,
    totalHits: results?.nbHits,
    content: (
      <div className="tableBody">
        {hits.map((org: AlgoliaOrg) => (
          <div key={org.objectID} className="tableRow">
            <TableCellTooltip content={formatDate(org.created)} />
            <TableCellTooltip content={org.orgID}>
              <Link to={`/org/${org.orgID}`} className="link">
                {org.orgID}
              </Link>
            </TableCellTooltip>
            <TableCellTooltip content={org.orgName} />
            <TableCellTooltip content={org.orgEmail}>
              <a href={`mailto: ${org.orgEmail}`}>{org.orgEmail}</a>
            </TableCellTooltip>
            <TableCellTooltip content={org.orgPhone}>
              <a href={`tel: ${org.orgPhone}`}>{org.orgPhone}</a>
            </TableCellTooltip>

            <TableCellTooltip content={org?.country || ''}>
              <CountryFlag countryCode={org?.country} />
            </TableCellTooltip>
          </div>
        ))}
      </div>
    )
  };

  return <AlgoliaTable {...table} />;
};
