import { useState } from 'react';
import { useMutation } from 'react-query';
import { Form, Formik } from 'formik';
import CurrencyInput from 'react-currency-input-field';

import { BudgetPayload, Org } from 'api/orgs/types';

import { updateOrgBudget } from 'api/orgs';
import { OfficeSelectField } from './OfficeSelectField';
import { Modal } from 'components/ui/Modal';

interface Props {
  org: Org;
  data:
    | false
    | {
        budget: number;
        officeID: string;
      };
  close: () => void;
  refetchOrg: () => void;
}

interface FormikState {
  officeID: string;
}

export const BudgetFormModal = ({ org, data, close, refetchOrg }: Props) => {
  const [budgetValue, setBudgetValue] = useState(data ? String(data.budget) : '');

  const initialValues: FormikState = {
    officeID: data ? data.officeID : ''
  };

  const { mutate, isLoading } = useMutation(updateOrgBudget);

  const onSubmit = (values: FormikState) => {
    const payload: BudgetPayload = {
      type: 'update',
      orgID: org.metadata.orgID,
      officeID: values.officeID,
      budget: Number(budgetValue) * 100
    };

    mutate(payload, {
      onSuccess: () => {
        close();
        refetchOrg();
      }
    });
  };

  return (
    <Modal title="Add Budget" open={true} close={close} className="budgetModal">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <OfficeSelectField org={org} isPreview={data ? true : false} />
          <div className="field">
            <label>Set Budget</label>
            <CurrencyInput
              value={budgetValue}
              prefix={org?.metadata?.country === 'GBR' ? '£' : '$'}
              decimalsLimit={2}
              decimalScale={2}
              onValueChange={(value: any) => setBudgetValue(value)}
              required
            />
          </div>
          <button type="submit" className="contained mt24" disabled={isLoading}>
            Save changes
          </button>
        </Form>
      </Formik>
    </Modal>
  );
};
