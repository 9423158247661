import { OrgOrderItem } from 'types/orgs.types';

import { OrderItem } from 'types/order.types';

import { formatProductHref, formatUom, showLastPurchased } from 'util/mix';
import { useHistoryItemsStore } from 'stores/HistoryItemsStore';
import { convertPrice, orderSupplierPrice } from 'util/prices';
import { Modal } from 'components/ui/Modal';
import { CountryCode } from 'types/util.types';

interface Props {
  items: (OrgOrderItem | OrderItem | any)[];
  countryCode?: CountryCode;
  close: () => void;
}

export const OrderItemsReviewer = ({ items, close, countryCode }: Props) => {
  const { historyItems } = useHistoryItemsStore();

  return (
    <Modal open={true} close={close} title="Items" className="orderItemsModal">
      <ul className="customScrollbar">
        {items.map(({ item, price, quantity }, index) => (
          <li key={`${index}-${item.objectID}`}>
            <div className="imgBox">
              <img src={item.images[0]} alt="Faliam" />
            </div>
            <div className="info">
              <a
                href={formatProductHref(item.objectID || '', item.title)}
                className="link"
                rel="noreferrer"
                target="_blank"
              >
                {item.title} <span>x {quantity}</span>
              </a>
              <p>
                <span>{convertPrice(orderSupplierPrice(price), countryCode)}</span>
                {formatUom(item)}
              </p>
              <p>{showLastPurchased(historyItems, item.objectID, item.uom)}</p>
            </div>
          </li>
        ))}
      </ul>
    </Modal>
  );
};
