import { useState } from 'react';
import { useMutation } from 'react-query';

import { StoreOrgOrderPayload } from 'api/orgs/types';
import { OrgOrderItem } from 'types/orgs.types';

import { DownArrowIcon } from 'assets/icons/DownArrow';
import { useSupplierLogo } from 'hooks/useSupplierLogo';
import { groupOrgOrdersBySupplier } from 'util/mix';
import { convertPrice, orderItemsTotalPrice, orderSupplierPrice } from 'util/prices';
import { createOrgOrder } from 'api/orgs';
import { CountryCode } from 'types/util.types';

interface Props {
  countryCode?: CountryCode;
  payload: StoreOrgOrderPayload;
  orderItems: OrgOrderItem[];
  taxRate: number;
  orgID: string;
  onSuccess: () => void;
}

export const Summary = ({ payload, orderItems, taxRate, orgID, onSuccess, countryCode }: Props) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const totalPrice = orderItemsTotalPrice(orderItems);
  const grouped = groupOrgOrdersBySupplier(orderItems);
  const { getSupplierLogo } = useSupplierLogo();
  const { mutate, isLoading } = useMutation((body: StoreOrgOrderPayload) => createOrgOrder(orgID, body));

  const toggle = (supplier: string) => {
    if (expanded.includes(supplier)) {
      setExpanded((prev) => prev.filter((el) => el !== supplier));
    } else {
      setExpanded((prev) => [...prev, supplier]);
    }
  };

  const onSubmit = () => {
    const body: StoreOrgOrderPayload = {
      ...payload,
      Items: orderItems.map((item) => ({
        variantID: item.item.objectID,
        providerName: item.price.provider,
        quantity: item.quantity,
        promotion: item.price.promotion
      }))
    };
    mutate(body, { onSuccess });
  };

  return (
    <div className="paper summary">
      <div className="paperHeader">
        <h2>Order Summary ({orderItems.length} items)</h2>
        <p>{convertPrice(totalPrice, countryCode)}</p>
      </div>
      <div className="paperBody">
        <div className="suppliers">
          {grouped.map(([supplier, items]) => (
            <div key={supplier} className="supplier">
              <div className="flexBetween" onClick={() => toggle(supplier)}>
                <div className="flexAlign gg8">
                  <div className="supplierLogoBox">
                    <img src={getSupplierLogo(supplier)} alt={supplier} />
                  </div>
                  <p>
                    {supplier} ({items.length} items)
                  </p>
                </div>
                <DownArrowIcon />
              </div>
              {expanded.includes(supplier) && (
                <div className="items mt16">
                  {items.map(({ item, price }) => (
                    <div key={`${supplier}${item.objectID}`} className="flexBetween">
                      <p>{item.title}</p>
                      <p>{convertPrice(orderSupplierPrice(price), countryCode)}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="details">
          <div className="flexBetween">
            <p>Shipping & handling</p>
            <p>Free</p>
          </div>
          <div className="flexBetween">
            <p>Total before tax</p>
            <p>{convertPrice(totalPrice, countryCode)}</p>
          </div>
          <div className="flexBetween">
            <p>Estimated tax to be collected</p>
            <p>{convertPrice(taxRate * totalPrice, countryCode)}</p>
          </div>
          <div className="flexBetween total">
            <p>Total</p>
            <p>{convertPrice(taxRate * totalPrice + totalPrice, countryCode)}</p>
          </div>
        </div>
        <button className="contained" disabled={(orderItems.length ? false : true) || isLoading} onClick={onSubmit}>
          Create Order
        </button>
      </div>
    </div>
  );
};
