/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { LoaderIcon } from 'assets/icons/LoaderIcon';
import {
  getOrgOrders,
  getOrg,
  getOrgFormularyItems,
  getOrgInvoices,
  getOrgHistoryItems,
  getOrgDraftOrders
} from 'api/orgs';
import { orgsKeys } from 'api/orgs/queries';
import { useProvidersStrore } from 'stores/ProvidersStore';
import { Hero } from './hero';
import { OrgInformations } from './OrgInformations';
import { OrgOffices } from './offices/OrgOffices';
import { OrgPaymentsTable } from './payments/PaymentsTable';
import { OrgUsersTable } from './users/OrgUsersTable';
import { OrgUserInvitationsTable } from './Invitations/OrgUserInvitationsTable';
import { OrgOrdersTable } from './orders/OrgOrdersTable';
import { SuppliersTable } from './suppliers/SuppliersTable';
import { FormularyItemsTable } from './formulary/FormularyItemsTable';
import { CreateNewOrder } from './orders/createNewOrder';
import { InvoicesTable } from './invoices/InvoicesTable';
import { BudgetsTable } from './budgets/BudgetsTable';
import { useHistoryItemsStore } from 'stores/HistoryItemsStore';
import { DraftOrdersTable } from './draftOrders/DraftOrdersTable';
import { OrgInvoice } from '../../api/orgs/types';
import { OrgGposTable } from './gpos/OrgGposTable';

export const OrganizationPage = () => {
  const [createOrderModal, setCreateOrderModal] = useState({
    status: false,
    officeID: ''
  });

  const [fetchInvoicesParams, setFetchInvoicesParams] = useState<{ maxResults: number; pageToken?: string }>({
    maxResults: 500
  });
  const [allInvoices, setAllInvoices] = useState<OrgInvoice[]>([]);

  const { orgID }: any = useParams();
  const { providers } = useProvidersStrore();
  const { setHistoryItems } = useHistoryItemsStore();

  const { data: org, status, refetch } = useQuery(orgsKeys.org(orgID), () => getOrg(orgID));
  const { data: orders, refetch: fetchOrders } = useQuery(orgsKeys.orgOrders(orgID), () => getOrgOrders(orgID));
  const { data: formularyItems, refetch: refetchFormularyItems } = useQuery(orgsKeys.formulary(orgID), () =>
    getOrgFormularyItems(orgID)
  );
  const { data: invoicesResponse, refetch: refetchInvoices } = useQuery(orgsKeys.invoices(orgID), () =>
    getOrgInvoices({ orgID, ...fetchInvoicesParams })
  );
  const { data: historyItems, refetch: refetchOrgHistoryItems } = useQuery(orgsKeys.historyItems(orgID), () =>
    getOrgHistoryItems(orgID)
  );
  const { data: draftOrders, refetch: refetchOrgDraftOrders } = useQuery(orgsKeys.draftorders(orgID), () =>
    getOrgDraftOrders(orgID)
  );

  useEffect(() => {
    if (org) refetchOrgHistoryItems();
  }, [org]);

  useEffect(() => {
    if (historyItems) setHistoryItems(historyItems);
  }, [historyItems]);

  useEffect(() => {
    refetchInvoices();
  }, [fetchInvoicesParams]);

  useEffect(() => {
    if (invoicesResponse?.pageToken)
      setFetchInvoicesParams((prev) => ({ ...prev, pageToken: invoicesResponse.pageToken }));
  }, [invoicesResponse]);

  useEffect(() => {
    if (invoicesResponse?.invoices) setAllInvoices((prev) => [...prev, ...invoicesResponse.invoices]);
  }, [invoicesResponse?.invoices]);

  if (status === 'error') return <h1 className="pageTitle center">Organization not found</h1>;
  if (status === 'loading') return <LoaderIcon />;
  if (!org) return null;

  const props = { org, refetchOrg: refetch };

  const refetchAll = () => {
    refetch();
    fetchOrders();
    refetchFormularyItems();
    refetchInvoices();
    refetchOrgDraftOrders();
  };

  return (
    <div className="orgPage">
      <Hero {...props} />
      <OrgInformations {...props} />
      <OrgOffices {...props} setOfficeID={(officeID) => setCreateOrderModal({ status: true, officeID })} />
      <OrgPaymentsTable {...props} />
      <BudgetsTable {...props} orders={orders || []} />
      <OrgUsersTable {...props} />
      <OrgUserInvitationsTable {...props} />
      <OrgGposTable gpos={org?.gpos || []} />
      {orders && (
        <OrgOrdersTable
          org={org}
          orders={orders}
          openCreateOrderModal={() => setCreateOrderModal({ status: true, officeID: '' })}
        />
      )}
      {draftOrders && (
        <DraftOrdersTable
          countryCode={org?.metadata?.country}
          draftOrders={draftOrders}
          refetch={refetchOrgDraftOrders}
        />
      )}
      {<InvoicesTable invoices={allInvoices || []} org={org} orders={orders} refetch={refetchAll} />}
      <SuppliersTable allProviders={providers} org={org} />
      {formularyItems && (
        <FormularyItemsTable org={org} items={formularyItems} refetchFormularyItems={refetchFormularyItems} />
      )}
      {createOrderModal.status && (
        <CreateNewOrder
          officeID={createOrderModal.officeID}
          close={() => setCreateOrderModal({ status: false, officeID: '' })}
          org={org}
          refetch={() => {
            refetch();
            fetchOrders();
          }}
        />
      )}
    </div>
  );
};
