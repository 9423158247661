import { FormikProps } from 'formik';
import get from 'lodash/get';
import ReactSelect from 'react-select';
import CreatableReactSelect from 'react-select/creatable';

import reactSelectStylesConfig from 'lib/react-select';

interface Option {
  value: string | null;
  label: string;
}

interface Props {
  formik: FormikProps<any>;
  label: string;
  name: string;
  options: Option[];
  isClearable?: boolean;
  isCreatable?: boolean;
}

export type InputT = Props & React.InputHTMLAttributes<HTMLInputElement>;

export const SelectInput = ({ formik, name, label, options, isClearable, isCreatable }: InputT) => {
  const hasError =
    (get(formik.touched, name) && get(formik.errors, name)) || (formik.submitCount > 0 && get(formik.errors, name));

  const props = {
    placeholder: `Select ${label}`,
    options,
    value: options.find(({ value }) => value === get(formik.values, name)) || null,
    onBlur: () => formik.handleBlur({ target: { name: name } }),
    onChange: (option: any) => formik.setFieldValue(name, option ? option.value : null),
    styles: reactSelectStylesConfig,
    isClearable
  };

  return (
    <div className={`input-field ${hasError ? 'invalid' : 'valid'}`}>
      <label>{label}</label>

      {isCreatable ? <CreatableReactSelect {...props} /> : <ReactSelect {...props} />}

      {hasError && <span className="errorMsg">{get(formik.errors, name) as string}</span>}
    </div>
  );
};
