import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Provider } from 'api/providers/types';
import { newProvider } from 'api/providers';
import { InputField } from 'components/ui/Input';
import { SelectInput } from 'components/ui/SelectInput';
import { isActiveClass } from 'util/classes';

interface Props {
  onClose: () => void;
  onSuccess: (p: Provider) => void;
}

const CATEGORY_OPTIONS = [
  { value: 'PARTNER', label: 'Partner' },
  { value: 'PROCUREMENT', label: 'Procurement' }
];

const COUNTRY_OPTIONS = [
  { label: 'United States (USA)', value: 'USA' },
  { label: 'United Kingdom (GBR)', value: 'GBR' }
];

export const SupplierFormModal = ({ onClose, onSuccess }: Props) => {
  const { mutate: createMutate, isLoading: createIsLoading } = useMutation(newProvider);

  const formik = useFormik({
    initialValues: {
      name: '',
      image: '',
      category: '',
      country: undefined,
      connections: {
        offline: false,
        online: false
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      image: Yup.string()
        .url()
        .matches(/^https:\/\/clippingmagic\.com\/.*$/, 'Image must be from clippingmagic.com'),
      category: Yup.string().required().oneOf(['PARTNER', 'PROCUREMENT']),
      connections: Yup.object().shape({
        offline: Yup.boolean().required(),
        online: Yup.boolean().required()
      })
    }),
    onSubmit: (values, { resetForm, setErrors }) => {
      const body: Provider = {
        name: values.name,
        country: values?.country,
        image: values.image,
        category: values.category == 'PARTNER' ? 'PARTNER' : 'PROCUREMENT',
        connections: values.connections
      };

      // Otherwise, we're creating a new supplier
      createMutate(body, {
        onSuccess: (data) => {
          onSuccess(data);
          resetForm();
          onClose();
        },
        onError: (err: any) => setErrors({ name: new Error(err).message })
      });
    }
  });

  return (
    <form className="modal" onSubmit={formik.handleSubmit}>
      <div className="hero">
        <h2>New Supplier</h2>
        <div className="right">
          <button type="button" className="outlined lg" onClick={onClose} disabled={createIsLoading}>
            Discard changes
          </button>
          <button
            type="submit"
            className="contained lg"
            disabled={createIsLoading || Object.keys(formik.errors).length > 0}
          >
            Create Supplier
          </button>
        </div>
      </div>
      <div className="modalOuter">
        <div className="paper">
          <div className="header">
            <h2>Product information</h2>
          </div>
          <div className="body">
            <InputField formik={formik} name="name" label="Name" placeholder="Supplier Name" />
            <InputField formik={formik} name="image" label="Image" placeholder="https://clippingmagic.com/..." />
            <SelectInput formik={formik} name="category" label="Category" options={CATEGORY_OPTIONS} />
            <SelectInput formik={formik} name="country" label="Country" options={COUNTRY_OPTIONS} />
            <div className="input-field w-fit flexAlign gg8">
              <label>Offline Supported</label>
              <div
                className="switchBtn"
                onClick={() => formik.setFieldValue('connections.offline', !formik.values.connections.offline)}
              >
                <div className={isActiveClass('buffer', formik.values.connections.offline)} />
              </div>
              <label>Online Supported</label>
              <div
                className="switchBtn"
                onClick={() => formik.setFieldValue('connections.online', !formik.values.connections.online)}
              >
                <div className={isActiveClass('buffer', formik.values.connections.online)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
