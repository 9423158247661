/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import { useQuery } from 'react-query';

import { Org, StoreOrgOrderPayload } from 'api/orgs/types';
import { OrgOrderItem } from 'types/orgs.types';

import { FaliamLogoIcon } from 'assets/icons/FaliamLogo';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { fillMoreItemsStateWithOrderItems } from 'util/orders';

import { MoreItems } from 'components/shared/moreItems';
import { MoreItemsState } from 'components/shared/moreItems/AlgoliaHits';
import { Offices } from './Offices';
import { Summary } from './Summary';
import { orgsKeys } from 'api/orgs/queries';
import { getOrgOfficeTaxRate } from 'api/orgs';
import { orderItemsTotalPrice } from 'util/prices';
import { OrgOrderItems } from 'components/shared/orgs/OrgOrderItems';

interface Props {
  close: () => void;
  org: Org;
  officeID: string;
  refetch: () => void;
}

export const CreateNewOrder = ({ close, org, officeID, refetch }: Props) => {
  const [addedItems, setAddedItems] = useState<MoreItemsState[]>([]);
  const [items, setItems] = useState<OrgOrderItem[]>([]);
  const [payload, setPayload] = useState<StoreOrgOrderPayload>({
    FromCart: true,
    CustomNote: '',
    Items: [],
    OfficeID: officeID || org.offices[0].officeID
  });
  const orgID = org.metadata.orgID;
  const amount = orderItemsTotalPrice(items);

  const { data: taxRate, refetch: fetchTaxRate } = useQuery(
    orgsKeys.officeTaxRate(orgID, payload.OfficeID, amount),
    () => getOrgOfficeTaxRate(orgID, payload.OfficeID, amount),
    { enabled: false }
  );

  useEffect(() => {
    if (payload.OfficeID && amount) fetchTaxRate();
  }, [payload.OfficeID, amount]);

  const getQuantity = (el: MoreItemsState) =>
    items.find((item) => item.item.objectID === el.objectID && item.price.provider === el.supplier.name)?.quantity || 1;

  const convertMoreItemsFormatToOrderItem = (items: MoreItemsState[]): OrgOrderItem[] =>
    items.map(({ supplier, ...rest }) => ({
      item: rest,
      price: {
        buyingPrice: supplier.price,
        sellingPrice: supplier.price,
        customSellingPrice: supplier.price,
        provider: supplier.name,
        sku: supplier.sku,
        promotion: supplier.promotions?.[0]
      },
      quantity: getQuantity({ ...rest, supplier }),
      backOrder: null,
      cancelled: ''
    }));

  return (
    <Portal>
      <div className="createNewOrgOrderModal">
        <div className="createNewOrgOrderModalHeader">
          <FaliamLogoIcon />
          <CloseIcon onClick={close} />
        </div>
        <div className="createNewOrgOrderModalBody">
          <p className="title">Create Order For {org.metadata.orgName}</p>
          <div className="grid">
            <div className="paper">
              <div className="paperHeader">
                <h2>1. Search and add products</h2>
              </div>
              <div className="paperBody">
                <MoreItems
                  org={org}
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  callback={(newItems) => setItems(convertMoreItemsFormatToOrderItem(newItems))}
                />
                <div className="mt16">
                  <OrgOrderItems
                    orderItems={items}
                    setOrderItems={(items) => {
                      setAddedItems(fillMoreItemsStateWithOrderItems(items));
                      setItems(items);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <Offices
                org={org}
                activeOffice={payload.OfficeID}
                setActiveOffice={(id) => setPayload((prev) => ({ ...prev, OfficeID: id }))}
              />
            </div>
            <Summary
              countryCode={org?.metadata?.country}
              orgID={orgID}
              payload={payload}
              orderItems={items}
              taxRate={taxRate || 0}
              onSuccess={() => {
                close();
                refetch();
              }}
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};
