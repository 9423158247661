import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import orderBy from 'lodash/orderBy';

import { PayFaliamInvoicePayload } from 'api/purchaseOrders/types';
import { HandleCellOrders } from 'types/table.types';
import { Org, OrgInvoice } from 'api/orgs/types';
import { OrgOrder } from 'types/orgs.types';

import { PayInvoiceSubmitProps, SelectPayment } from 'components/shared/payments/SelectPayment';
import { TableCellTooltip } from 'components/shared/table/TableCell';
import { payFaliamInvoice } from 'api/purchaseOrders';
import { formatOfficeAddress } from 'util/offices';
import { Table } from 'components/shared/table';
import useTableData from 'hooks/useTableData';
import { isActiveClass } from 'util/classes';
import { convertPrice } from 'util/prices';
import { formatDate } from 'util/dates';

interface Props {
  invoices: OrgInvoice[];
  refetch: () => void;
  org: Org;
  orders?: OrgOrder[];
}

export const InvoicesTable = ({ org, orders, invoices, refetch }: Props) => {
  const [isOnlyFaliamInvoices, setIsOnlyFaliamInvoices] = useState(false);
  const [isOnlyPaidInvoices, setIsOnlyPaidInvoices] = useState(false);
  const [isOnlyUnpaidInvoices, setIsOnlyUnpaidInvoices] = useState(false);
  const [itemForPay, setItemForPay] = useState<OrgInvoice | null>(null);
  const [data, setData] = useState<OrgInvoice[]>([]);

  const { meta, tableData } = useTableData({
    allData: data
  });

  const { mutate, isLoading } = useMutation(payFaliamInvoice);
  const orgID = org.metadata.orgID;

  const activeInvoices = useMemo(() => {
    let res = invoices;

    if (isOnlyFaliamInvoices) res = invoices.filter((el) => el.provider.toLowerCase() === 'faliam');

    if (isOnlyPaidInvoices) res = invoices.filter((el) => el.paid && el.payable);
    if (isOnlyUnpaidInvoices) res = invoices.filter((el) => !el.paid && el.payable);

    return orderBy(
      res.map((invoice) => ({
        ...invoice,
        office: orders?.find((order) => order.orderID === invoice.orderID)?.office
      })),
      (el) => formatDate(el.created),
      'desc'
    );
  }, [invoices, isOnlyFaliamInvoices, isOnlyPaidInvoices, isOnlyUnpaidInvoices, orders]);

  useEffect(() => {
    setData(activeInvoices);
  }, [activeInvoices]);

  const onPay = (res: PayInvoiceSubmitProps) => {
    if (!itemForPay) return;

    const payload: PayFaliamInvoicePayload = {
      orgID,
      orderID: itemForPay.orderID,
      invoiceID: itemForPay.invoiceID
    };
    if (res.paymentID) payload.paymentID = res.paymentID;
    if (res.checkInfo) payload.checkInfo = res.checkInfo;

    mutate(payload, {
      onSuccess: () => {
        refetch();
        setItemForPay(null);
      }
    });
  };

  const handleCellOrders = ({ cell, direction }: HandleCellOrders) => {
    let res: any[] = [];

    switch (cell) {
      case 'Date Ordered':
        res = orderBy(activeInvoices, (el) => formatDate(el.created), direction);
        break;
      case 'Order ID':
        res = orderBy(activeInvoices, 'orderID', direction);
        break;
      case 'Due Date':
        res = orderBy(activeInvoices, (el) => formatDate(el.due), direction);
        break;
      case 'Total':
        res = orderBy(activeInvoices, 'total', direction);
        break;
      default:
        res = data;
        break;
    }

    setData(res);
  };

  const buttonsFlow = (invoice: OrgInvoice) => {
    if (invoice.payable) {
      if (invoice.paid) return <div className="badge success">Paid</div>;
      return (
        <button className="contained sm" onClick={() => setItemForPay(invoice)}>
          Pay Invoice
        </button>
      );
    }
  };

  const isAllFaliamInvoicesPaid = invoices
    .filter((el) => el.provider.toLowerCase() === 'faliam')
    .every((invoice) => invoice.paid);

  return (
    <>
      <div className="space" />
      <Table
        name="OrgInvoices"
        title="Invoices"
        nav={['Date Ordered', 'Order ID', 'Address', 'Office Name', 'Due Date', 'Total', 'Status']}
        handleCellOrders={handleCellOrders}
        allData={activeInvoices}
        setData={setData}
        searchKeys={['orderID', 'office.name']}
        headerContent={
          <div className="flexAlign gg16">
            <span className="fz14">
              All Faliam Invoices Paid:{' '}
              <span className={isAllFaliamInvoicesPaid ? 'colorSuccess' : 'colorDanger'}>
                {isAllFaliamInvoicesPaid ? 'Yes' : 'No'}
              </span>
            </span>
            <span>·</span>
            <div
              className="switchBtn"
              onClick={() => {
                setIsOnlyPaidInvoices((p) => !p);
                setIsOnlyUnpaidInvoices(false);
              }}
            >
              <p>Only Paid</p>
              <div className={isActiveClass('buffer', isOnlyPaidInvoices)} />
            </div>
            <span>·</span>
            <div
              className="switchBtn"
              onClick={() => {
                setIsOnlyUnpaidInvoices((p) => !p);
                setIsOnlyPaidInvoices(false);
              }}
            >
              <p>Only Unpaid</p>
              <div className={isActiveClass('buffer', isOnlyUnpaidInvoices)} />
            </div>
            <span>·</span>
            <div className="switchBtn" onClick={() => setIsOnlyFaliamInvoices((p) => !p)}>
              <p>Only Faliam Invoices</p>
              <div className={isActiveClass('buffer', isOnlyFaliamInvoices)} />
            </div>
          </div>
        }
        meta={meta}
        content={
          <div className="tableBody customScrollbar">
            {tableData.map((invoice) => {
              return (
                <div key={invoice.invoiceID} className="tableRow">
                  <TableCellTooltip content={formatDate(invoice.created)} />
                  <TableCellTooltip content={invoice.orderID}>
                    <Link to={`/order/${orgID}/${invoice.orderID}`} className="link">
                      {invoice.orderID}
                    </Link>
                  </TableCellTooltip>
                  <TableCellTooltip content={invoice.office ? formatOfficeAddress(invoice.office) : ''} />
                  <TableCellTooltip content={invoice?.office?.name || ''} />
                  <TableCellTooltip content={formatDate(invoice.due)} />
                  <TableCellTooltip content={convertPrice(invoice.total, org?.metadata?.country)} />
                  <div className="tableCell">{buttonsFlow(invoice)}</div>
                </div>
              );
            })}
          </div>
        }
      />
      {itemForPay && (
        <SelectPayment
          payments={org.payments}
          close={() => setItemForPay(null)}
          onSubmit={onPay}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
