import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { Org, OrgMetadata } from 'api/orgs/types';

import { updateOrg } from 'api/orgs';
import { CountryFlag } from 'components/shared/CountryFlag';

interface Props {
  org: Org;
  refetchOrg: () => void;
}

export const OrgInformations = ({ org, refetchOrg }: Props) => {
  const { orgName, orgEmail, orgPhone, orgID } = org.metadata;
  const { mutate, isLoading } = useMutation((body: OrgMetadata) => updateOrg(orgID, body));

  const onSubmit = (values: any) => {
    const body = { ...org.metadata, ...values };
    mutate(body, { onSuccess: refetchOrg });
  };

  return (
    <Formik
      initialValues={{
        orgName: orgName || '',
        orgEmail: orgEmail || '',
        orgPhone: orgPhone || ''
      }}
      onSubmit={onSubmit}
    >
      <Form className="paper orgInfoBlock">
        <div className="paperHeader">
          <h2>Informations</h2>
          <button className="contained auto-width lg" type="submit" disabled={isLoading}>
            Save changes
          </button>
        </div>
        <div className="paperBody">
          <div className="fields">
            <div className="field">
              <label>Country</label>
              <CountryFlag countryCode={org?.metadata?.country} />
            </div>

            <div className="field">
              <label htmlFor="orgName">Organization Name</label>
              <Field name="orgName" required />
            </div>
            <div className="field">
              <label htmlFor="orgName">Organization Email</label>
              <Field type="email" name="orgEmail" />
            </div>
            <div className="field">
              <label htmlFor="orgName">Organization Phone</label>
              <Field name="orgPhone" />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
