import { Configure, InstantSearch } from 'react-instantsearch-hooks';

import { Org } from 'api/orgs/types';
import { MoreItemsState } from './AlgoliaHits';

import { algoliaKeys, searchClient } from 'lib/algolia/aloglia-config';
import { Container } from './container';

export interface MoreItemsProps {
  org?: Org;
  addedItems: MoreItemsState[];
  setAddedItems: React.Dispatch<React.SetStateAction<MoreItemsState[]>>;
  callback: (items: MoreItemsState[], newItems: MoreItemsState[], op: 'create' | 'delete') => void;
  isFormulary?: boolean;
}

export const MoreItems = (props: MoreItemsProps) => {
  const countryFilters = props?.org?.metadata?.country === 'GBR' ? 'AND country:GBR' : 'AND NOT country:GBR';
  return (
    <div className="moreItems">
      <InstantSearch indexName={algoliaKeys.indexName.regular} searchClient={searchClient}>
        {/* @ts-ignore */}
        <Configure filters={`NOT unavailable:true ${countryFilters}`} />
        <Container {...props} />
      </InstantSearch>
    </div>
  );
};
