import { FormikProps } from 'formik';
import { SelectInput } from 'components/ui/SelectInput';
import { SelectState } from 'types/util.types';

const COUNTRY_SELECT_OPTIONS: SelectState[] = [
  { label: 'United States (USA)', value: 'USA' },
  { label: 'United Kingdom (GBR)', value: 'GBR' }
];

type Props = {
  formik: FormikProps<any>;
  name?: string;
};

export const CountrySelect = ({ formik, name }: Props) => {
  return <SelectInput label="Country" name={name || 'country'} options={COUNTRY_SELECT_OPTIONS} formik={formik} />;
};
