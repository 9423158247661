import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { queryClient } from 'lib/react-quert';
import { awsConfig } from 'lib/aws/config';
import { App } from 'App';
import 'styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Amplify.configure(awsConfig);

root.render(
  <Authenticator.Provider>
    <QueryClientProvider client={queryClient}>
      <ToastContainer position="top-right" autoClose={3000} closeOnClick hideProgressBar={true} theme="light" />
      <App />
    </QueryClientProvider>
  </Authenticator.Provider>
);
