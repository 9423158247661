import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { StoreGpoPayload } from 'api/gpo/types';

import { storeGpos } from 'api/gpo';
import { Modal } from 'components/ui/Modal';
import { CountrySelect } from 'components/shared/CountrySelect';

interface Props {
  close: () => void;
  refetch: () => void;
}

export const CreateGpoForm = ({ close, refetch }: Props) => {
  const initialValues: StoreGpoPayload = { name: '', image: '', country: undefined };
  const { mutate, isLoading } = useMutation(storeGpos);

  const onSubmit = (values: StoreGpoPayload) => {
    mutate(values, {
      onSuccess: () => {
        refetch();
        close();
      }
    });
  };

  return (
    <Modal open={true} close={close} title="Create GPO" className="createGpoModal">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <div className="field">
              <label>Name</label>
              <Field name="name" required />
            </div>
            <div className="field">
              <label>Image</label>
              <Field name="image" required />
            </div>
            <div className="field">
              <CountrySelect formik={formik} />
            </div>
            <button className="contained mt24" type="submit" disabled={isLoading}>
              Create GPO
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
